import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Routes, Route, useParams, useNavigate, useLocation } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import ManagePage from './ManagePage';
import { validateAndNormalizeUrl } from './utils';
import { FaInstagram, FaTiktok, FaYoutube, FaSpotify, FaSoundcloud } from 'react-icons/fa';

const stripePromise = loadStripe('pk_test_51PQC0OEYvVzWccAjqROEh3A64guEgQu6mvcP30udgfojRZwoVPINOvKjKV2tK99Pu6Yu65WagnLOjmJKtezjjglJ00touStSew'); // Replace with your Stripe publishable key

const CheckoutForm = ({ onSuccess }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [email, setEmail] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const { data: { clientSecret } } = await axios.post('/api/create-payment-intent', {
        firstName,
        email,
      });

      const payload = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: firstName,
            email: email,
          },
        }
      });

      if (payload.error) {
        setError(`Payment failed: ${payload.error.message}`);
        setLoading(false);
      } else {
        setLoading(false);
        onSuccess(firstName, email);
      }
    } catch (err) {
      setError(`Error: ${err.message}`);
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label className="block text-sm font-medium text-gray-700">First Name</label>
        <input
          type="text"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          required
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700">Email</label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
      </div>
      <CardElement className="mt-4" />
      {error && <div className="text-red-500 mt-2">{error}</div>}
      <button
        type="submit"
        className="w-full bg-blue-500 text-white px-4 py-2 mt-4 rounded-md shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        disabled={!stripe || loading}
      >
        {loading ? 'Processing...' : 'Pay $3.00'}
      </button>
    </form>
  );
};

const VerifyToken = ({ onSuccess }) => {
  const { customDomain } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');

    const verifyToken = async () => {
      try {
        const response = await axios.post('/api/verify-token', { token, customDomain });
        if (response.data.success) {
          onSuccess();
        } else {
          alert('Invalid or expired token');
          navigate('/');
        }
      } catch (error) {
        console.error('Error verifying token:', error);
        alert('Error verifying token');
        navigate('/');
      } finally {
        setLoading(false);
      }
    };

    verifyToken();
  }, [customDomain, location.search, navigate, onSuccess]);

  return loading ? <div>Loading...</div> : null;
};

const extractYouTubeVideoID = (url) => {
  const patterns = [
    /(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([^"&?/]{11})/,
    /(?:https?:\/\/)?(?:www\.)?youtu\.be\/([^"&?/]{11})/,
    /(?:https?:\/\/)?(?:www\.)?youtube\.com\/embed\/([^"&?/]{11})/
  ];
  
  for (const pattern of patterns) {
    const match = url.match(pattern);
    if (match && match[1]) {
      return match[1];
    }
  }
  
  return null;
};

const normalizeYouTubeUrl = (url) => {
  const videoID = extractYouTubeVideoID(url);
  if (videoID) {
    return `https://www.youtube.com/embed/${videoID}?rel=0`;
  }
  return null;
};

const App = () => {
  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [message, setMessage] = useState('');
  const [videoUrl, setVideoUrl] = useState('');
  const [backgroundColor, setBackgroundColor] = useState('#212121');  // Default color set to #212121
  const [customDomain, setCustomDomain] = useState('');
  const [displayCustomDomain, setDisplayCustomDomain] = useState('');
  const [urlAvailable, setUrlAvailable] = useState(null);
  const [isCreated, setIsCreated] = useState(false);
  const [font, setFont] = useState('Arial');
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [pageUrl, setPageUrl] = useState('');
  const [metrics, setMetrics] = useState({ views: 0, button_clicks: 0 });
  const [buttonName, setButtonName] = useState('');
  const [buttonLink, setButtonLink] = useState('');
  const [buttonColor, setButtonColor] = useState('#007BFF'); // Default button color

  const [instagramLink, setInstagramLink] = useState('');
  const [tiktokLink, setTiktokLink] = useState('');
  const [youtubeLink, setYoutubeLink] = useState('');
  const [spotifyLink, setSpotifyLink] = useState('');
  const [soundcloudLink, setSoundcloudLink] = useState('');

  const handleColorChange = (e) => {
    setBackgroundColor(e.target.value);
  };

  const handleCustomDomainChange = async (e) => {
    const newCustomDomain = e.target.value.toLowerCase();
    const displayDomain = e.target.value;
    setCustomDomain(newCustomDomain);
    setDisplayCustomDomain(displayDomain);

    try {
      const response = await axios.get(`/api/check-domain/${newCustomDomain}`);
      setUrlAvailable(response.data.available);
    } catch (err) {
      console.error('Error checking domain availability', err);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isCreated) {
      handleUpdate();
    } else {
      setShowPaymentForm(true);
    }
  };

  const handlePaymentSuccess = async (firstName, email) => {
    const normalizedVideoUrl = normalizeYouTubeUrl(videoUrl);
    if (!normalizedVideoUrl) {
      alert('Invalid YouTube URL');
      return;
    }

    const normalizedLink = buttonLink ? validateAndNormalizeUrl(buttonLink) : '';
    if (!normalizedLink && buttonLink) {
      alert('Invalid URL in Button Link. Please enter a valid link starting with http:// or https://');
      return;
    }

    const data = {
      title,
      subtitle,
      message,
      videoUrl: normalizedVideoUrl,
      backgroundColor,
      customDomain,
      font,
      firstName,
      email,
      button_name: buttonName,
      button_link: normalizedLink,
      button_color: buttonColor,
      instagram_link: instagramLink,
      tiktok_link: tiktokLink,
      youtube_link: youtubeLink,
      spotify_link: spotifyLink,
      soundcloud_link: soundcloudLink
    };

    try {
      const response = await axios.post('/api/create', data);
      const pageUrl = `https://yt.watch/${customDomain}`;
      setPageUrl(pageUrl);
      setIsCreated(true);
      setShowPaymentForm(false);
      fetchMetrics(response.data.id); // Fetch metrics after page creation
      alert('Payment successful! Your custom page has been created.');
    } catch (err) {
      console.error('Error creating page', err);
      alert('Failed to create page');
    }
  };

  const handleUpdate = async () => {
    const normalizedVideoUrl = normalizeYouTubeUrl(videoUrl);
    if (!normalizedVideoUrl) {
      alert('Invalid YouTube URL');
      return;
    }

    const normalizedLink = buttonLink ? validateAndNormalizeUrl(buttonLink) : '';
    if (!normalizedLink && buttonLink) {
      alert('Invalid URL in Button Link. Please enter a valid link starting with http:// or https://');
      return;
    }

    const data = {
      title,
      subtitle,
      message,
      videoUrl: normalizedVideoUrl,
      backgroundColor,
      font,
      button_name: buttonName,
      button_link: normalizedLink,
      button_color: buttonColor,
      instagram_link: instagramLink,
      tiktok_link: tiktokLink,
      youtube_link: youtubeLink,
      spotify_link: spotifyLink,
      soundcloud_link: soundcloudLink
    };

    try {
      const response = await axios.put(`/api/update/${customDomain}`, data);
      const pageUrl = `https://yt.watch/${customDomain}`;
      setPageUrl(pageUrl);
      alert(`Page updated at ${pageUrl}`);
    } catch (err) {
      console.error('Error updating page', err);
      alert('Failed to update page');
    }
  };

  const handleDeletePage = async () => {
    try {
      await axios.delete(`/api/delete/${customDomain}`);
      alert('Page deleted successfully');
      setIsCreated(false);
      setPageUrl(''); // Clear the page URL after deletion
    } catch (error) {
      console.error('Error deleting page', error);
      alert('Error deleting page');
    }
  };

  const toggleInfoModal = () => {
    setShowInfoModal(!showInfoModal);
  };

  const renderMessage = (message) => {
    return message.split('\n').map((line, index) => (
      <p key={index} id="previewMessage" className="mb-2 text-left">{line}</p> // Added id="previewMessage"
    ));
  };

  const fetchMetrics = async (videoId) => {
    try {
      const response = await axios.get(`/api/metrics/${videoId}`);
      setMetrics(response.data);
    } catch (error) {
      console.error('Error fetching metrics:', error);
    }
  };

  const trackEvent = async (action) => {
    try {
      await axios.post(`/api/track/${customDomain}`, { action });
    } catch (error) {
      console.error('Error tracking event:', error);
    }
  };

  const renderSocialIcons = () => (
    <div className="flex space-x-4 mt-4">
      {instagramLink && (
        <a href={instagramLink} target="_blank" rel="noopener noreferrer">
          <FaInstagram size={30} className="text-white hover:text-gray-300" />
        </a>
      )}
      {tiktokLink && (
        <a href={tiktokLink} target="_blank" rel="noopener noreferrer">
          <FaTiktok size={30} className="text-white hover:text-gray-300" />
        </a>
      )}
      {youtubeLink && (
        <a href={youtubeLink} target="_blank" rel="noopener noreferrer">
          <FaYoutube size={30} className="text-white hover:text-gray-300" />
        </a>
      )}
      {spotifyLink && (
        <a href={spotifyLink} target="_blank" rel="noopener noreferrer">
          <FaSpotify size={30} className="text-white hover:text-gray-300" />
        </a>
      )}
      {soundcloudLink && (
        <a href={soundcloudLink} target="_blank" rel="noopener noreferrer">
          <FaSoundcloud size={30} className="text-white hover:text-gray-300" />
        </a>
      )}
    </div>
  );

  return (
    <Elements stripe={stripePromise}>
      <Router>
        <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center">
          <header className="fixed top-0 w-full bg-red-600 text-white p-4 flex justify-between items-center shadow-lg z-10 h-16 lg:h-12">
            <h1 className="text-xl font-bold">yt.watch</h1>
            <button className="block" onClick={toggleInfoModal}>
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2"></circle>
                <path d="M12 16v-4m0-4h.01" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
              </svg>
            </button>
          </header>

          <Routes>
            <Route path="/" element={
              <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-6xl flex flex-col lg:flex-row mt-24 lg:mt-16" style={{ minHeight: '80vh' }}>
                <div className="w-full lg:w-1/3 lg:pr-4 mb-6 lg:mb-0 scrollable">
                  <h1 className="text-3xl font-bold mb-6 text-center responsive-header">Create YT Watch Page</h1>
                  <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Video Link</label>
                      <input type="text" placeholder="https://www.youtube.com/watch?v=2AKs..." value={videoUrl} onChange={(e) => setVideoUrl(e.target.value)} required className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" disabled={isCreated} />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Title</label>
                      <input type="text" placeholder="Title" value={title} onChange={(e) => setTitle(e.target.value)} required className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Subtitle</label>
                      <input type="text" placeholder="Subtitle" value={subtitle} onChange={(e) => setSubtitle(e.target.value)} className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Message</label>
                      <textarea
                        placeholder="Message"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      ></textarea>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Background Color</label>
                      <input type="color" value={backgroundColor} onChange={handleColorChange} className="mt-1 block w-full h-10 p-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Button Name</label>
                      <input type="text" placeholder="Button Name" value={buttonName} onChange={(e) => setButtonName(e.target.value)} className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Button Link</label>
                      <input type="text" placeholder="Button Link" value={buttonLink} onChange={(e) => setButtonLink(e.target.value)} className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Button Color</label>
                      <input type="color" value={buttonColor} onChange={(e) => setButtonColor(e.target.value)} className="mt-1 block w-full h-10 p-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Instagram Link</label>
                      <input
                        type="text"
                        placeholder="Instagram URL"
                        value={instagramLink}
                        onChange={(e) => setInstagramLink(e.target.value)}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">TikTok Link</label>
                      <input
                        type="text"
                        placeholder="TikTok URL"
                        value={tiktokLink}
                        onChange={(e) => setTiktokLink(e.target.value)}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">YouTube Link</label>
                      <input
                        type="text"
                        placeholder="YouTube URL"
                        value={youtubeLink}
                        onChange={(e) => setYoutubeLink(e.target.value)}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Spotify Link</label>
                      <input
                        type="text"
                        placeholder="Spotify URL"
                        value={spotifyLink}
                        onChange={(e) => setSpotifyLink(e.target.value)}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">SoundCloud Link</label>
                      <input
                        type="text"
                        placeholder="SoundCloud URL"
                        value={soundcloudLink}
                        onChange={(e) => setSoundcloudLink(e.target.value)}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Font</label>
                      <select value={font} onChange={(e) => setFont(e.target.value)} className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                        <option value="Arial">Arial</option>
                        <option value="Verdana">Verdana</option>
                        <option value="Tahoma">Tahoma</option>
                        <option value="Georgia">Georgia</option>
                        <option value="Courier New">Courier New</option>
                        <option value="Times New Roman">Times New Roman</option>
                        <option value="Trebuchet MS">Trebuchet MS</option>
                        <option value="Lucida Sans Unicode">Lucida Sans Unicode</option>
                        <option value="Roboto">Roboto</option>
                        <option value="Open Sans">Open Sans</option>
                        <option value="Lato">Lato</option>
                        <option value="Oswald">Oswald</option>
                        <option value="Montserrat">Montserrat</option>
                      </select>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Custom URL</label>
                      <div className="flex mt-1">
                        <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">yt.watch/</span>
                        <input type="text" placeholder="this" value={displayCustomDomain} onChange={handleCustomDomainChange} required className={`flex-1 block w-full min-w-0 rounded-none rounded-r-md px-3 py-2 border ${urlAvailable === null ? 'border-gray-300' : urlAvailable ? 'border-green-500' : 'border-red-500'} focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`} disabled={isCreated} />
                      </div>
                      {urlAvailable === false && (
                        <p className="text-red-500 text-sm mt-1">This URL is not available.</p>
                      )}
                    </div>
                    <div className="text-center">
                      <button type="submit" className={`w-full ${isCreated ? 'bg-green-600' : 'bg-red-600'} text-white px-4 py-2 rounded-md shadow-sm hover:${isCreated ? 'bg-green-700' : 'bg-red-700'} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-${isCreated ? 'green' : 'red'}-500`}>
                        {isCreated ? 'Update Page' : 'Create Page 🔒'}
                      </button>
                      {pageUrl && (
                        <a href={pageUrl} target="_blank" rel="noopener noreferrer" className="mt-4 w-full bg-blue-600 text-white px-4 py-2 rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 block text-center">
                          View Page
                        </a>
                      )}
                    </div>
                  </form>
                  {isCreated && (
                    <div className="text-center mt-4">
                      <button
                        onClick={handleDeletePage}
                        className="w-full bg-purple-500 text-white px-4 py-2 rounded-md shadow-sm hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                      >
                        Delete Page
                      </button>
                    </div>
                  )}
                </div>
                <div className="w-full lg:w-2/3 lg:pl-4 flex items-center justify-center mt-6 lg:mt-0">
                  <div
                    className="w-full h-full flex flex-col items-center justify-center p-4 rounded-md shadow-inner"
                    style={{
                      backgroundColor: backgroundColor,
                      minHeight: '70vh',
                      fontFamily: font,
                    }}
                  >
                    <h2 id="previewTitle" className="text-xl font-bold mb-2 text-center text-white">{title || 'Title'}</h2>
                    <h3 id="previewSubtitle" className="text-lg text-gray-200 mb-4 text-center">{subtitle || 'Subtitle'}</h3>
                    {videoUrl && (
                      <div className="relative w-full" style={{ paddingBottom: '56.25%' }}> {/* 16:9 aspect ratio */}
                        <iframe
                          className="absolute top-0 left-0 w-full h-full rounded-md"
                          src={normalizeYouTubeUrl(videoUrl)}
                          frameBorder="0"
                          allow="fullscreen"
                          title="YouTube video preview"
                        ></iframe>
                      </div>
                    )}
                    <div id="previewMessage" className="text-gray-200 text-left mt-4" style={{ maxWidth: '80%' }}>
                      {renderMessage(message || 'Message')}
                    </div>
                    {buttonName && buttonLink && (
                      <a
                        href={buttonLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-block bg-blue-500 text-white px-4 py-2 mt-4 rounded-md shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        style={{ backgroundColor: buttonColor }}
                        onClick={() => trackEvent('button_click')}
                      >
                        {buttonName}
                      </a>
                    )}
                    {renderSocialIcons()}
                    <footer className="w-full text-center py-4 mt-8 shadow-inner" style={{ backgroundColor: backgroundColor }}>
                      <a href="https://yt.watch" target="_blank" rel="noopener noreferrer" className="text-white">&copy; {new Date().getFullYear()} yt.watch</a>
                    </footer>
                  </div>
                </div>
              </div>
            } />
            <Route path="/manage/:customDomain" element={<ManagePage />} />
            <Route path="/verify/:customDomain" element={<VerifyToken onSuccess={() => setIsCreated(true)} />} />
          </Routes>

          <footer className="w-full bg-red-600 text-center py-4 mt-8 shadow-inner">
            <div className="text-white">
              <a href="https://yt.watch" target="_blank" rel="noopener noreferrer">&copy; {new Date().getFullYear()} yt.watch</a>
            </div>
          </footer>

          {showPaymentForm && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-30">
              <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
                <h2 className="text-2xl font-bold mb-4 text-center">Complete Your Payment</h2>
                <Elements stripe={stripePromise}>
                  <CheckoutForm onSuccess={handlePaymentSuccess} />
                </Elements>
                <button
                  onClick={() => setShowPaymentForm(false)}
                  className="mt-4 w-full bg-gray-500 text-white px-4 py-2 rounded-md shadow-sm hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                >
                  Cancel
                </button>
              </div>
            </div>
          )}

          {showInfoModal && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-30">
              <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-lg">
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-2xl font-bold">How It Works</h2>
                  <button onClick={toggleInfoModal}>
                    <svg
                      className="w-6 h-6"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      ></path>
                    </svg>
                  </button>
                </div>
                <div className="text-left">
                  <p className="text-gray-700 mb-4">
                    Fill in the form with your desired video link, title, subtitle, and message. Choose a background color and font, and create a custom URL. <br /><br />
                    Once you submit, you will be prompted to complete your payment. After the payment is successful, your custom yt.watch page will be created and you can share it with anyone! <br /><br />
                    You can update and delete your page by navigating back to the session tab or via the link sent to your payment email. Pages expire after 30 days and cost $3.
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </Router>
    </Elements>
  );
};

export default App;
