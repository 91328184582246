import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { FaInstagram, FaTiktok, FaYoutube, FaSpotify, FaSoundcloud } from 'react-icons/fa';
import { validateAndNormalizeUrl } from './utils';

const ManagePage = () => {
  const { customDomain } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [message, setMessage] = useState('');
  const [videoUrl, setVideoUrl] = useState('');
  const [backgroundColor, setBackgroundColor] = useState('#212121');
  const [font, setFont] = useState('Arial');
  const [pageUrl, setPageUrl] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [metrics, setMetrics] = useState({ views: 0, button_clicks: 0 });
  const [videoId, setVideoId] = useState(null);
  const [buttonName, setButtonName] = useState('');
  const [buttonLink, setButtonLink] = useState('');
  const [buttonColor, setButtonColor] = useState('#007BFF'); // Default button color

  const [instagramLink, setInstagramLink] = useState('');
  const [tiktokLink, setTiktokLink] = useState('');
  const [youtubeLink, setYoutubeLink] = useState('');
  const [spotifyLink, setSpotifyLink] = useState('');
  const [soundcloudLink, setSoundcloudLink] = useState('');

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');

    const fetchData = async () => {
      try {
        const response = await axios.get(`/api/fetch/${customDomain}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (response.data) {
          const { id, title, subtitle, message, video_url, background_color, font, button_name, button_link, button_color, instagram_link, tiktok_link, youtube_link, spotify_link, soundcloud_link } = response.data;
          setVideoId(id);
          setTitle(title);
          setSubtitle(subtitle);
          setMessage(message);
          setVideoUrl(video_url);
          setBackgroundColor(background_color);
          setFont(font);
          setButtonName(button_name || '');
          setButtonLink(button_link || '');
          setButtonColor(button_color || '#007BFF'); // Set default button color if not present
          setInstagramLink(instagram_link || '');
          setTiktokLink(tiktok_link || '');
          setYoutubeLink(youtube_link || '');
          setSpotifyLink(spotify_link || '');
          setSoundcloudLink(soundcloud_link || '');
          setPageUrl(`https://yt.watch/${customDomain}`);
          fetchMetrics(id); // Fetch metrics when page data is loaded
        } else {
          alert('Invalid or expired token');
          navigate('/');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        alert('Error fetching data');
        navigate('/');
      }
    };

    fetchData();
  }, [customDomain, location.search, navigate]);

  const extractYouTubeVideoID = (url) => {
    const regex = /(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([^"&?/]{11})|youtu\.be\/([^"&?/]{11})|youtube\.com\/embed\/([^"&?/]{11})/;
    const match = url.match(regex);
    return match ? match[1] || match[2] || match[3] : null;
  };

  const normalizeYouTubeUrl = (url) => {
    const videoID = extractYouTubeVideoID(url);
    if (videoID) {
      return `https://www.youtube.com/embed/${videoID}?rel=0`;
    }
    return null;
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setIsUpdating(true);
    const params = new URLSearchParams(location.search);
    const token = params.get('token');

    const normalizedVideoUrl = normalizeYouTubeUrl(videoUrl);
    if (!normalizedVideoUrl) {
      alert('Invalid YouTube URL');
      setIsUpdating(false);
      return;
    }

    try {
      const normalizedButtonLink = buttonLink ? validateAndNormalizeUrl(buttonLink) : '';
      const data = {
        title,
        subtitle,
        message,
        videoUrl: normalizedVideoUrl,
        backgroundColor,
        font,
        button_name: buttonName,
        button_link: normalizedButtonLink,
        button_color: buttonColor,
        instagram_link: instagramLink,
        tiktok_link: tiktokLink,
        youtube_link: youtubeLink,
        spotify_link: spotifyLink,
        soundcloud_link: soundcloudLink
      };

      await axios.put(`/api/update/${customDomain}`, data, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      alert('Page updated successfully');
    } catch (error) {
      console.error('Error updating page:', error);
      alert(error.message);
    } finally {
      setIsUpdating(false);
    }
  };

  const handleDelete = async () => {
    setIsDeleting(true);
    const params = new URLSearchParams(location.search);
    const token = params.get('token');

    try {
      await axios.delete(`/api/delete/${customDomain}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      alert('Page deleted successfully');
      navigate('/');
    } catch (error) {
      console.error('Error deleting page:', error);
      alert('Failed to delete page');
    } finally {
      setIsDeleting(false);
    }
  };

  const renderMessage = (message) => {
    return message.split('\n').map((line, index) => (
      <p key={index} className="mb-2">{line}</p>
    ));
  };

  const fetchMetrics = async (videoId) => {
    try {
      const response = await axios.get(`/api/metrics/${videoId}`);
      setMetrics(response.data);
    } catch (error) {
      console.error('Error fetching metrics:', error);
    }
  };

  const trackButtonClick = async (videoId) => {
    try {
      await axios.post(`/api/track/${videoId}`, { action: 'button_click' });
      fetchMetrics(videoId); // Update metrics after tracking the click
    } catch (error) {
      console.error('Error tracking button click:', error);
    }
  };

  const renderSocialIcons = () => (
    <div className="flex space-x-4 mt-4">
      {instagramLink && (
        <a href={instagramLink} target="_blank" rel="noopener noreferrer">
          <FaInstagram size={30} className="text-white hover:text-gray-300" />
        </a>
      )}
      {tiktokLink && (
        <a href={tiktokLink} target="_blank" rel="noopener noreferrer">
          <FaTiktok size={30} className="text-white hover:text-gray-300" />
        </a>
      )}
      {youtubeLink && (
        <a href={youtubeLink} target="_blank" rel="noopener noreferrer">
          <FaYoutube size={30} className="text-white hover:text-gray-300" />
        </a>
      )}
      {spotifyLink && (
        <a href={spotifyLink} target="_blank" rel="noopener noreferrer">
          <FaSpotify size={30} className="text-white hover:text-gray-300" />
        </a>
      )}
      {soundcloudLink && (
        <a href={soundcloudLink} target="_blank" rel="noopener noreferrer">
          <FaSoundcloud size={30} className="text-white hover:text-gray-300" />
        </a>
      )}
    </div>
  );

  return (
    <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-6xl flex flex-col lg:flex-row mt-24 lg:mt-16" style={{ minHeight: '80vh' }}>
      <div className="w-full lg:w-1/3 lg:pr-4 mb-6 lg:mb-0 scrollable">
        <h1 className="text-3xl font-bold mb-6 text-center responsive-header">Manage YT Watch Page</h1>
        <form onSubmit={handleUpdate} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Video Link</label>
            <input type="text" placeholder="https://www.youtube.com/watch?v=2AKs..." value={videoUrl} onChange={(e) => setVideoUrl(e.target.value)} required disabled className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Title</label>
            <input type="text" placeholder="Title" value={title} onChange={(e) => setTitle(e.target.value)} required className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Subtitle</label>
            <input type="text" placeholder="Subtitle" value={subtitle} onChange={(e) => setSubtitle(e.target.value)} className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Message</label>
            <textarea
              placeholder="Message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            ></textarea>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Background Color</label>
            <input type="color" value={backgroundColor} onChange={(e) => setBackgroundColor(e.target.value)} className="mt-1 block w-full h-10 p-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Font</label>
            <select value={font} onChange={(e) => setFont(e.target.value)} className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
              <option value="Arial">Arial</option>
              <option value="Verdana">Verdana</option>
              <option value="Tahoma">Tahoma</option>
              <option value="Georgia">Georgia</option>
              <option value="Courier New">Courier New</option>
              <option value="Times New Roman">Times New Roman</option>
              <option value="Trebuchet MS">Trebuchet MS</option>
              <option value="Lucida Sans Unicode">Lucida Sans Unicode</option>
              <option value="Roboto">Roboto</option>
              <option value="Open Sans">Open Sans</option>
              <option value="Lato">Lato</option>
              <option value="Oswald">Oswald</option>
              <option value="Montserrat">Montserrat</option>
            </select>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Button Name</label>
            <input
              type="text"
              placeholder="Button Name"
              value={buttonName}
              onChange={(e) => setButtonName(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Button Link</label>
            <input
              type="text"
              placeholder="Button Link"
              value={buttonLink}
              onChange={(e) => setButtonLink(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Button Color</label>
            <input
              type="color"
              value={buttonColor}
              onChange={(e) => setButtonColor(e.target.value)}
              className="mt-1 block w-full h-10 p-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Instagram Link</label>
            <input
              type="text"
              placeholder="Instagram URL"
              value={instagramLink}
              onChange={(e) => setInstagramLink(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">TikTok Link</label>
            <input
              type="text"
              placeholder="TikTok URL"
              value={tiktokLink}
              onChange={(e) => setTiktokLink(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">YouTube Link</label>
            <input
              type="text"
              placeholder="YouTube URL"
              value={youtubeLink}
              onChange={(e) => setYoutubeLink(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Spotify Link</label>
            <input
              type="text"
              placeholder="Spotify URL"
              value={spotifyLink}
              onChange={(e) => setSpotifyLink(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">SoundCloud Link</label>
            <input
              type="text"
              placeholder="SoundCloud URL"
              value={soundcloudLink}
              onChange={(e) => setSoundcloudLink(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div className="text-center">
            <button
              type="submit"
              className="w-full bg-green-600 text-white px-4 py-2 rounded-md shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
            >
              {isUpdating ? 'Processing...' : 'Update Page'}
            </button>
            {pageUrl && (
              <a href={pageUrl} target="_blank" rel="noopener noreferrer" className="mt-4 w-full bg-blue-600 text-white px-4 py-2 rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 block text-center">
                View Page
              </a>
            )}
          </div>
        </form>
        <div className="text-center mt-4">
          <button
            onClick={handleDelete}
            className="w-full bg-purple-500 text-white px-4 py-2 rounded-md shadow-sm hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
          >
            {isDeleting ? 'Processing...' : 'Delete Page'}
          </button>
        </div>
        <div className="text-center mt-4">
          <h2 className="text-xl font-bold mb-4">Page Metrics</h2>
          <p>Views: {metrics.views}</p>
          <p>Button Clicks: {metrics.button_clicks}</p>
        </div>
      </div>
      <div className="w-full lg:w-2/3 lg:pl-4 flex items-center justify-center mt-6 lg:mt-0">
        <div
          className="w-full h-full flex flex-col items-center justify-center p-4 rounded-md shadow-inner"
          style={{
            backgroundColor: backgroundColor,
            minHeight: '70vh',
            fontFamily: font,
          }}
        >
          <h2 id="previewTitle" className="text-xl font-bold mb-2 text-center text-white">{title || 'Title'}</h2>
          <h3 id="previewSubtitle" className="text-lg text-gray-200 mb-4 text-center">{subtitle || 'Subtitle'}</h3>
          {videoUrl && (
            <div className="relative w-full" style={{ paddingBottom: '56.25%' }}> {/* 16:9 aspect ratio */}
              <iframe
                className="absolute top-0 left-0 w-full h-full rounded-md"
                src={normalizeYouTubeUrl(videoUrl)}
                frameBorder="0"
                allow="fullscreen"
                title="YouTube video preview"
              ></iframe>
            </div>
          )}
          <div id="previewMessage" className="text-gray-200 text-left mt-4" style={{ maxWidth: '80%' }}>
            {renderMessage(message || 'Message')}
          </div>
          {buttonName && buttonLink && (
            <a
              href={buttonLink}
              target="_blank"
              rel="noopener noreferrer"
              className="mt-4 px-4 py-2 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
              style={{ backgroundColor: buttonColor, color: '#fff' }}
              onClick={() => trackButtonClick(videoId)}
            >
              {buttonName}
            </a>
          )}
          {renderSocialIcons()}
          <footer className="w-full text-center py-4 mt-8 shadow-inner" style={{ backgroundColor: backgroundColor }}>
            <a href="https://yt.watch" target="_blank" rel="noopener noreferrer" className="text-white">&copy; {new Date().getFullYear()} yt.watch</a>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default ManagePage;
