export const validateAndNormalizeUrl = (url) => {
  try {
    const normalizedUrl = new URL(url);
    if (normalizedUrl.protocol === 'http:' || normalizedUrl.protocol === 'https:') {
      return normalizedUrl.toString();
    }
    return null;
  } catch (err) {
    return null;
  }
};

export const extractYouTubeVideoID = (url) => {
  const patterns = [
    /(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([^"&?/]{11})/,
    /(?:https?:\/\/)?(?:www\.)?youtu\.be\/([^"&?/]{11})/,
    /(?:https?:\/\/)?(?:www\.)?youtube\.com\/embed\/([^"&?/]{11})/
  ];
  
  for (const pattern of patterns) {
    const match = url.match(pattern);
    if (match && match[1]) {
      return match[1];
    }
  }
  
  return null;
};
